#wrapper {
    position: relative;
    overflow: hidden;
}

.container {
    max-width: 1160px;
    padding: 0 25px;
    margin: 0 auto;
}

.accessibility {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.accessibility:focus {
    position: static;
    width: auto;
    height: auto;
}

a:focus,
input:focus,
textarea:focus {
    outline: 2px solid $light-blue !important;
}

.logo {
    width: 115px;

    a {
        display: block;
    }

    img {
        display: block;
    }
}

.line-decor {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        height: 10px;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            45deg,
            #00682f 0%,
            #00682f 15.2%,
            #efdf00 15.2%,
            #efdf00 25.8%,
            #00a339 25.8%,
            #00a339 36.5%,
            #00ae43 36.5%,
            #00ae43 50.7%,
            #00682f 50.7%,
            #00682f 86%,
            #efdf00 86%,
            #efdf00 96.7%,
            #00a339 96.7%,
            #00a339 100%
        );
    }
}

.social {
    @extend %listreset;

    margin: 0 -7px;
    display: flex;

    @include media(">=tablet") {
        margin: 0 -10px;
    }

    li {
        padding: 0 7px;
        display: inline-block;
        vertical-align: middle;

        @include media(">=tablet") {
            padding: 0 10px;
        }
    }
}

.decor-border {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 2px;
        background: #f2f2f2;
    }
}

a {
    @include animate(color);

    text-decoration: none;
    color: #868f96;

    &:hover {
        color: $black;
    }
}

.switch-language {
    position: relative;
    padding-left: 31px;
    margin-left: auto;
    font-size: 18px;
    font-family: $tstar;
    font-weight: 400;

    .icon-speach {
        font-size: 21px;
        margin-right: 10px;
    }
}

h1,
.h1,
h2,
.h2 {
    font-weight: 400;
    line-height: 1.1;
}

h3,
.h3 {
    font-family: $tstar;
    line-height: 1.1;
}

main {
    @include animate(padding);

    padding: 34px 0 0;

    @include media(">=tablet") {
        padding: 32px 0 140px;
    }
}

.active-section .take-action {
    opacity: 0;
    visibility: visible;
    z-index: -1;
}

.take-action {
    @include animate(background opacity visibility 0.3s);

    background-color: $pink;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    width: 100%;
    font-size: 28px;
    padding: 11px 24px 17px;
    display: inline-block;
    vertical-align: middle;
    font-family: $oxfam-global;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    visibility: visible;

    @include media(">=tablet") {
        display: none;
    }

    &:hover {
        background-color: darken($pink, 10%);
        color: $white;
    }
}

.cookies-block {
    background-color: $black;
    color: $white;
    padding: 20px 16px 15px;
    font: 600 12px/1.2375 $tstar, sans-serif;
    display: none;
    /* position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	display: none; */

    @include media(">=tablet") {
        font-size: 14px;
    }

    .cookies-block-wrap {
        margin: 0 auto;
        max-width: 1140px;
    }

    a {
        color: $pink;

        &:hover {
            color: $white;
        }
    }

    h4,
    .h4 {
        font-family: $tstar;
        color: $light-green;
        text-transform: uppercase;
        margin-bottom: 7px;
        display: block;
    }

    p {
        margin-bottom: 13px;
    }

    .btn {
        color: $white;
        text-transform: uppercase;
        padding: 2px 13px 6px 12px;
        min-width: 123px;
        font-size: 18px;
        font-weight: 400;
    }
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"] {
    @include animate(border);

    height: 45px;
    padding: 5px 15px;

    @include placeholder {
        color: $gray-200;
    }
}

.btn {
    @include animate(background color border);

    padding: 17px 7px 25px;
    text-align: center;
    background-color: transparent;
    color: $black;
    border: 1px solid $gray;
    vertical-align: middle;
    display: inline-block;
    font-family: $oxfam-global;

    &:hover {
        background-color: $gray;
    }
}

.btn-primary {
    background-color: $green;
    border-color: transparent;
    color: $white;

    &:hover {
        background-color: darken($green, 8%);
    }
}

.btn-secondary {
    background-color: $pink;
    border-color: transparent;
    color: $white;

    &:hover {
        background-color: darken($pink, 8%);
    }
}

.heading-holder {
    text-align: center;
    margin-bottom: 22px;

    @include media(">=tablet") {
        margin-bottom: 67px;
    }

    h1 {
        color: $light-green;
        margin-bottom: 17px;
        letter-spacing: 0.6px;

        @include media(">=tablet") {
            margin-bottom: 15px;
            letter-spacing: 0;
        }
    }
}

.two-column {
    display: flex;
    flex-wrap: wrap;

    @include media(">=tablet") {
        margin: 0 -15px;
    }

    p {
        margin-bottom: 23px;
    }
}

.content-block {
    @include media(">=tablet") {
        width: 50%;
        padding: 0 15px;
    }

    @include media(">=desktop") {
        width: 58.35%;
    }

    &:only-child {
        width: 100%;
    }

    .image-holder {
        margin: 0 -25px;

        @include media(">=tablet") {
            margin: 0 0 37px;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .text-holder {
        background-color: $white;
        margin: -66px -17px 0;
        padding: 26px 15px 42px;
        position: relative;

        @include media(">=tablet") {
            padding: 0;
            margin: 0;
            background-color: transparent;
        }

        h3,
        .h3 {
            margin-bottom: 21px;

            @include media(">=tablet") {
                margin-bottom: 25px;
            }
        }
    }
}

.say-section {
    position: relative;

    @include media(">=tablet") {
        width: 50%;
        padding: 0 15px;
    }

    @include media(">=desktop") {
        width: 41.65%;
    }

    &:before {
        content: "";
        position: absolute;
        left: -25px;
        top: 0;
        right: -25px;
        bottom: 0;
        background: $light-gray;
        z-index: -1;

        @include media(">=tablet") {
            content: none;
        }
    }
}

.say-block {
    @include media(">=tablet") {
        padding: 0 24px 29px;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
    }

    .heading-block {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 -25px 25px;
        padding-top: 32px;

        @include media(">=tablet") {
            padding: 0 0 10px;
            margin-bottom: 25px;
            background-color: $black;
            background-image: none !important;
        }

        h2 {
            color: $white;
            background-color: $light-green;
            margin-right: 77px;
            font-size: 32px;
            padding: 11px 10px 12px 17px;
            font-weight: 400;
            text-align: left;
            letter-spacing: 0.3px;

            @include media(">=tablet") {
                margin: 0;
                background-color: transparent;
                font-size: 44px;
                letter-spacing: 0.5px;
                padding: 6px 0 0 17px;
            }
        }
    }

    .text-holder {
        margin-bottom: 39px;

        @include media(">=tablet") {
            margin-bottom: 37px;
        }

        h3,
        .h3 {
            margin-bottom: 15px;

            @include media(">=tablet") {
                margin-bottom: 16px;
            }
        }
    }

    .form-holder {
        margin-bottom: 15px;

        &:nth-of-type(3) {
            margin-bottom: 23px;
        }

        &:last-of-type {
            @include media(">=tablet") {
                margin-bottom: 27px;
            }
        }

        &.text-area {
            margin-bottom: 28px;
        }

        label {
            display: block;
            margin-bottom: 9px;
            font-weight: bold;
        }

        input,
        textarea {
            width: 100%;
        }

        textarea {
            padding: 2px 13px 14px 15px;
            resize: none;
            line-height: 1.18;
            height: 846px;

            @include media(">=tablet") {
                height: auto;
            }
        }
    }

    .h2 {
        padding: 0 25px;
        text-align: center;
        display: block;

        @include media(">=tablet") {
            text-align: left;
        }
    }

    .btn-list {
        @extend %listreset;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -4px 28px;

        @include media(">=tablet") {
            margin-bottom: 5px;
        }

        li {
            display: flex;
            flex: 1 1 auto;
            padding: 0 4px;
            margin-bottom: 8px;

            &:last-child {
                width: 100%;
            }

            .btn {
                width: 100%;
                font-size: 14px;
                padding: 16px 7px 18px;

                @include media(">=sm-phone") {
                    font-size: 18px;
                    padding: 16px 3px 18px;
                }

                @include media(">=phone") {
                    padding: 16px 7px 18px;
                }
            }
        }
    }
}
