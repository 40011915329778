#footer {
    padding: 45px 0 107px;
    background-color: $black;

    @include media(">=tablet") {
        padding: 38px 0 80px;
    }

    .footer-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:first-of-type {
            border-bottom: 2px solid #353a3c;
            padding-bottom: 25px;
            margin-bottom: 25px;

            @include media(">=tablet") {
                padding-bottom: 22px;
                margin-bottom: 17px;
            }
        }
    }

    .decor-border {
        &:before {
            background: #353a3c;
        }
    }

    .social {
        color: #939393;
        width: calc(100% - 110px);
        padding-top: 10px;

        @include media(">=tablet") {
            width: calc(100% - 250px);
            align-self: flex-start;
        }

        a {
            &:hover {
                color: $white;
            }
        }

        li:first-child {
            font-family: $roboto;
            padding-right: 4px;
            font-size: 14px;

            @include media(">=sm-phone") {
                font-size: 18px;
                letter-spacing: -0.5px;
            }
        }
    }

    .switch-language {
        color: $white;
        padding: 12px 0 9px 19px;

        &:hover {
            color: #939393;
        }

        .icon-speach {
            font-size: 18px;
            margin-right: 12px;

            @include media(">=tablet") {
                margin-right: 7px;
            }
        }
    }

    .text-block {
        font-size: 12px;
        width: 100%;
        color: #484d50;
        margin-bottom: 24px;

        @include media(">=tablet") {
            width: calc(100% - 150px);
            margin-bottom: 0;
        }

        p {
            margin-bottom: 15px;

            @include media(">=tablet") {
                margin-bottom: 21px;
            }
        }

        a {
            color: #484d50;

            &:hover {
                color: $white;
            }
        }
    }

    .logo {
        width: 91px;
        margin-left: auto;

        @include media(">=tablet") {
            margin-left: 0;
        }
    }

    .icon-facebook,
    .icon-twitter,
    .icon-youtube {
        line-height: 0;
        font-size: 0;
    }

    .icon-facebook:before,
    .icon-twitter:before,
    .icon-youtube:before {
        line-height: 1;
        font-size: 16px;
    }
}
