// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$light-gray: #eaeeef;
$gray: #ccc;
$gray-200: #434749;
$dark-gray: #353a3c;
$black: #262626;
$white: #fff;
$light-blue: #39f;
$green: #45a80d;
$light-green: #61a534;
$pink: #fb004f;

$base-font-sans-serif: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
$base-font-serif: "Times New Roman", "Times", "Baskerville", "Georgia", serif;
$source-sans-pro: "Source Sans Pro", "Helvetica Neue", "Helvetica", sans-serif;
$roboto: "Roboto", "Helvetica Neue", "Helvetica", sans-serif;

$tstar: "TSTAR", "Helvetica Neue", "Helvetica", sans-serif;
$oxfam-global: "Oxfam Global Headline", "Helvetica Neue", "Helvetica",
    sans-serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $gray-200 !default;
$base-background-color: $white !default;
$font-size-base: 16px !default;
$line-height-base: 1.2375;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $light-blue;

// Buttons
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 44px;
$h2-font-size: 32px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: $oxfam-global;
$headings-color: inherit !default;

$animation-speed: 0.15s;
