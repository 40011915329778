// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
    white-space: nowrap;
    text-align: center;

    &:#{$va-pseudo} {
        content: "";
        display: inline-block;
        vertical-align: $va-direction;
        width: 0;
        min-height: $va-height;
    }

    > * {
        white-space: normal;
        display: inline-block;
        vertical-align: $va-direction;
        max-width: 99%;
    }
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair(
    $child-name1,
    $child-name2,
    $valign: middle,
    $width1: auto,
    $width2: auto
) {
    display: table;
    .#{$child-name1} {
        display: table-cell;
        vertical-align: $valign;
        width: $width1;
    }
    .#{$child-name2} {
        display: table-cell;
        vertical-align: $valign;
        width: $width2;
    }
}

// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
}

/// Creates a visual triangle.
/// Mixin takes ($size, $color, $direction)
/// The $size argument can take one or two values—width height.
/// The $color argument can take one or two
/// values—foreground-color background-color.
///
/// @author http://bourbon.io/docs/#triangle
///
/// $direction:
/// up, down, left, right, up-right, up-left, down-right, down-left
/// @example scss - Usage
///   @include triangle(12px, gray, down);
///   @include triangle(12px 6px, gray blue, up-left);
///
@mixin triangle($size, $color, $direction) {
    $width: nth($size, 1);
    $height: nth($size, length($size));
    $foreground-color: nth($color, 1);
    $background-color: if(length($color) == 2, nth($color, 2), transparent);
    height: 0;
    width: 0;

    @if ($direction == up) or
        ($direction == down) or
        ($direction == right) or
        ($direction == left)
    {
        $width: $width / 2;
        $height: if(length($size) > 1, $height, $height/2);

        @if $direction == up {
            border-bottom: $height solid $foreground-color;
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
        } @else if $direction == right {
            border-bottom: $width solid $background-color;
            border-left: $height solid $foreground-color;
            border-top: $width solid $background-color;
        } @else if $direction == down {
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
            border-top: $height solid $foreground-color;
        } @else if $direction == left {
            border-bottom: $width solid $background-color;
            border-right: $height solid $foreground-color;
            border-top: $width solid $background-color;
        }
    } @else if ($direction == up-right) or ($direction == up-left) {
        border-top: $height solid $foreground-color;

        @if $direction == up-right {
            border-left: $width solid $background-color;
        } @else if $direction == up-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == down-right) or ($direction == down-left) {
        border-bottom: $height solid $foreground-color;

        @if $direction == down-right {
            border-left: $width solid $background-color;
        } @else if $direction == down-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == inset-up) {
        border-color: $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-down) {
        border-color: $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-right) {
        border-color: $background-color
            $background-color
            $background-color
            $foreground-color;
        border-style: solid;
        border-width: $width $height;
    } @else if ($direction == inset-left) {
        border-color: $background-color
            $foreground-color
            $background-color
            $background-color;
        border-style: solid;
        border-width: $width $height;
    }
}

/// Animate css properties
// usage
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: 0.3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
    $list: ();
    @each $prop in $properties {
        $str: #{$prop} #{$duration} #{$easing};
        $list: join($list, #{$str}, comma);
    }
    transition: $list;
}

/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &::-moz-placeholder {
        opacity: 1;
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &.placeholder {
        @content;
    }
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($pxSize) {
    font-size: $pxSize;
    font-size: ($pxSize / 10px) + rem;
}
