@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?fzbwu2");
    src: url("../fonts/icomoon.eot?fzbwu2#iefix") format("embedded-opentype"),
        url("../fonts/icomoon.ttf?fzbwu2") format("truetype"),
        url("../fonts/icomoon.woff?fzbwu2") format("woff"),
        url("../fonts/icomoon.svg?fzbwu2#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: "\e900";
}
.icon-speach:before {
    content: "\e901";
}
.icon-twitter:before {
    content: "\e902";
}
.icon-youtube:before {
    content: "\e903";
}
