@font-face {
    font-family: "Oxfam Global Headline";
    src: url("../fonts/OxfamGlobalHeadline-Regular.woff2") format("woff2"),
        url("../fonts/OxfamGlobalHeadline-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "TSTAR";
    src: url("../fonts/TSTAR-Bold.eot");
    src: url("../fonts/TSTAR-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/TSTAR-Bold.woff2") format("woff2"),
        url("../fonts/TSTAR-Bold.woff") format("woff"),
        url("../fonts/TSTAR-Bold.ttf") format("truetype"),
        url("../fonts/TSTAR-Bold.svg#TSTAR-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
}
