#header {
    border-bottom: 1px solid #e6e6e6;
    background-color: $white;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;

    .navbar {
        width: 100%;
        padding: 20px 15px 7px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media(">=tablet") {
            padding-top: 18px;
        }
    }

    .logo {
        padding-right: 15px;
        padding-top: 2px;
    }

    .slogan {
        display: none;

        @include media(">=tablet") {
            font-family: $tstar;
            padding: 8px 17px;
            width: calc(100% - 250px);
            display: block;
            font-size: 18px;
        }
    }

    .switch-language {
        display: flex;
        padding: 7px 0 10px 18px;

        .icon-speach {
            color: $green;
            margin-right: 12px;
        }
    }
}

.no-cookie {
    .headroom {
        will-change: transform;
        transition: transform 200ms linear;
    }
    .headroom--pinned {
        transform: translateY(0%);
    }
    .headroom--unpinned {
        transform: translateY(calc(-100% + 66px));

        @include media(">=tablet") {
            transform: translateY(calc(-100% + 64px));
        }
    }
}
